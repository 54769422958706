export default function btnFadeIn() {

    // 途中からボタンを表示
    $(window).scroll(function() {
        if ($(this).scrollTop() > 1000) {
            $('.pagetop').fadeIn();
        }
        else {
            $('.pagetop').fadeOut();
        }
    });
}