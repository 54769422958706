import Swiper from 'swiper';

export default function swiper() {

      let mySwiper = new Swiper ('.swiper-container', {
        effect: 'fade',
        speed: 4000,
        loop: true,
        loopAdditionalSlides: 4,
        autoplay: {
            delay: 4200,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            reverseDirection: false
        }
    });
}