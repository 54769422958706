export default function drawer() { 
   //  ドロワー
    var state = false;
    var scrollpos;
    $(".drawer-btn").on("click", function() {
        if(state == false) {
        scrollpos = $(window).scrollTop();
            $('body').addClass('scroll-prevent').css({'top': -scrollpos});
            $(this).addClass('active');
        $('.drawer-menu').addClass('open');
        state = true;
      } else {
        $('body').removeClass('scroll-prevent').css({'top': 0});
            window.scrollTo( 0 , scrollpos );
            $(this).removeClass('active');
        $('.drawer-menu').removeClass('open');
        state = false;
        }
    });
}