import UAParser from "ua-parser-js";

export default function UserAgent() {
  const list = [browserName, deviceType, deviceModel, fullosVersion, osName];
  list.forEach(x => addBody(x));
  console.log(browserName);
  if (browserName !== `ie`) console.log(`s`);
}
/**
 * https://github.com/faisalman/ua-parser-js#methods
 */
const parser = new UAParser().getResult();
const browser = parser.browser;
const browserName = convertResult(browser.name);
const browserVersion = convertResult(browser.version);
const browserMajor = convertResult(browser.major);
const device = parser.device;
const deviceModel = convertResult(device.model);
const deviceType = convertResult(device.type) || `pc`;
const deviceVendor = convertResult(device.vendor);
const os = parser.os;
const osName = convertResult(os.name);
const osVersion = convertResult(os.version.replace(/\..*/, ``));
const fullosVersion = convertResult(`${osName}${osVersion}`);
const engine = parser.engine;
const engineName = convertResult(engine.name);
const body = document.body;
function lower(target) {
  if (target) return target.toLowerCase();
}
function kebab(target) {
  if (target) return target.replace(/\s/g, `-`);
}
function convertResult(target) {
  if (target) return lower(kebab(target));
}
function addBody(target) {
  if (target) body.classList.add(target);
}