import "@babel/polyfill";
import picturefill from "picturefill";
import objectFitImages from 'object-fit-images';

/* Modules
==================== */

// スライダー
import swiper from "./modules/swiper";
swiper();

// ドロワー
import drawer from "./modules/drawer";
drawer();

// ページトップ
import pagetop from "./modules/pagetop";
pagetop();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// UA判定
import UserAgent from "./modules/ua";
UserAgent();


/* Script
==================== */
objectFitImages();
picturefill();
